<template>
  <ComponentWrapper id="projects" emoji="📁">
    <div class="project-list">
      <div v-for="(project, projectIndex) in projects" :key="projectIndex" class="project-card fade-text-up focus"
        tabindex="0">
        <div class="image">
          <img :src="project.image" :alt="project.title">
        </div>
        <div class="content">
          <div class="link">
            <img class="favicon" :src="project.favicon" alt="Favicon">
            {{ project.url }}
          </div>
          <h3 class="title">{{ project.title }}</h3>
          <div class="description">{{ project.description }}</div>
          <div class="tags">
            <span v-for="(tag, tagIndex) in project.gctags?.split(', ').slice(0, 5)" :key="tagIndex" class="tag">
              {{ tag }}
            </span>
            <span v-if="project.gctags?.split(', ').slice(5).length" class="tag">
              +{{ project.gctags?.split(', ').slice(4).length }}
            </span>
          </div>
        </div>

        <div class="cover">
          <img class="favicon" :src="project.favicon" alt="Favicon">
          <div class="title">{{ project.title }}</div>

          <div class="links">
            <button class="button" popovertarget="modal" @click="viewProject(project)">
              learn more
            </button>
          </div>
        </div>
      </div>
    </div>

    <ProjectModal id="modal" :selected-project="selectedProject" @hide="selectedProject = null" />
  </ComponentWrapper>
</template>
<script setup>
import ProjectModal from './ProjectModal.vue'
import ComponentWrapper from './ComponentWrapper.vue';
import { onMounted, ref } from 'vue';
import {
  PROJECT_LINKS,
  PROJECT_DATA,
  MetaScaper
} from '@/utils/projects'

const projects = ref([]);

onMounted(async () => {
  const result = await PROJECT_LINKS.map(async (url) => {
    const metaScaper = new MetaScaper(url)
    await metaScaper.scapeUrl()
    const ogmeta = metaScaper.getOGMeta();
    const gcmeta = metaScaper.getGCMeta();

    return { ...ogmeta, ...gcmeta }
  })
  try {
    const promiseProjects = await Promise.allSettled(result)

    let filteredProjects = promiseProjects.filter(project => {
      return project.status === "fulfilled"
    })
    filteredProjects = filteredProjects.map(project => project.value)
    projects.value = filteredProjects.concat(PROJECT_DATA)
  } catch (e) {
    console.log(e);
  }
})

const selectedProject = ref(null);
const viewProject = (project) => {
  selectedProject.value = project
}
</script>
<style lang="scss" scoped>
.project-list {
  display: flex;
  gap: 24px;
  flex-wrap: wrap;
  min-height: 350px;
}

.favicon {
  user-select: none;
  -webkit-user-drag: none;
}

.project-card {
  display: block;
  z-index: 1;
  border-radius: 8px;
  width: 300px;
  overflow: hidden;
  background-color: #2a2a2a;
  height: 350px;
  position: relative;
  animation-range: contain 5% contain 15%;

  .image {
    height: 150px;
    overflow: hidden;
    transition: $transitionOption;

    img {
      object-position: top center;
      object-fit: cover;
      width: 100%;
      height: 100%;
      transition: $transitionOption;
    }
  }

  .content {
    padding: 6px 8px 8px;
    transition: $transitionOption;

    .link {
      opacity: .3;
      font-size: 12px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      .favicon {
        max-height: 10px;
      }
    }

    .title {
      font-weight: 800;
      margin-top: .5rem;
      margin-bottom: .5rem;
    }

    .description {
      color: gray;
      // elipsis up to 3 lines
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .tags {
      margin-top: 16px;
      display: flex;
      flex-wrap: wrap;
      gap: 8px;

      .tag {
        padding: 4px 8px;
        border-radius: 4px;
        background: rgba(#fff, .3);
        font-size: .8rem;
      }
    }
  }

  .cover {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(0deg, #000, rgba(#000, .1));
    text-align: center;
    padding: 50px 20px;
    transition: $transitionOption;
    opacity: 0;
    z-index: -1;

    .favicon {
      height: 100px;
      margin-bottom: 30px;
    }

    .title {
      margin-bottom: 40px;
    }

    .links {
      display: flex;
      gap: 8px;
    }

    .button {
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &:hover,
  &:focus-visible,
  &:has(.button:focus-visible) {
    .image {
      height: 100%;
    }

    .content {
      opacity: .1;
    }

    .cover {
      z-index: 1;
      opacity: 1;
    }
  }
}
</style>