<template>
  <section id="viewOnDesktop" ref="viewOnDesktop">
    <div class="modal">
      Please view this site on a desktop or laptop with the latest version of Google Chrome or MS Edge for the best experience.
  
      <button class="button" @click="onOK">ok</button>
    </div>
  </section>
</template>
<script setup>
import { onMounted, ref } from 'vue';

const viewOnDesktop = ref(null)

onMounted(() => {
  const isOk = sessionStorage.getItem('viewOnDesktopModal');
  if(isMobile() && !isOk) {    
    viewOnDesktop.value.style.display = 'flex';
    document.getElementsByTagName('body')[0].style.overflow = 'hidden';
  }
})

const isMobile = () => {
  const regex = /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
  return regex.test(navigator.userAgent);
}

const onOK = () => {
  viewOnDesktop.value.style.display = 'none';
  document.getElementsByTagName('body')[0].style.overflow = 'auto';
  sessionStorage.setItem('viewOnDesktopModal', 'ok');
}
</script>
<style lang="scss" scoped>
#viewOnDesktop {
  text-align: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(3px);
  background: rgba(#fff, .5);
  display: none;

  .modal {
    max-width: 400px;
  }

  button {
    margin: 24px auto 0;
  }
}
</style>