export const TECH = {
  JS: "JavaScript",
  HTML: "HTML",
  CSS: "CSS",
  TS: "TypeScript",
  ANGULAR: "Angular",
  REACT: "React",
  VUE: "VueJs",
  JAVA: "Java",
  PYTHON: "Python",
  DJANGO: "Django",
  C: "C",
  SCSS: "SCSS",
  MYSQL: "MySQL",
  POSTGRESQL: "PostgeSQL",
  GIT: "Git",
  GITHUB: "GitHub",
  GITLAB: "GitLab",
  BITBUCKET: "BitBucket",
  ODOO: "Odoo",
  WORDPRESS: "Wordpress",
  PHP: "PHP",
  LARAVEL: "Laravel",
  VITE: "Vite",
  VUETIFY: "Vuetify"
}

export const DARK_TECH = [
  TECH.GITHUB
]

export const TOOLS = {
  AZURE: "Azure",
  NGINX: "Nginx",
  FIGMA: "Figma",
  MIRO: "Miro",
  DOCKER: "Docker",
  JIRA: "Jira",
  SLACK: "Slack"
}