<template>
  <ComponentWrapper id="contact" emoji="✉️">
    <div class="contact-wrapper">
      <div class="contacts">
        <a class="fade-text-up" v-for="(icon, iconIndex) in contactIcons" :key="iconIndex" :href="icon.url">
          <div class="image-wrapper">
            <img class="icon dark fade-text-up" :src="require(`@/assets/contact-icons/${icon.name}.svg`)" :alt="icon.name" target="_blank">
          </div>
        </a>
      </div>
      <div class="socials">
        <a class="fade-text-up" v-for="(icon, iconIndex) in socialIcons" :key="iconIndex" :href="icon.url">
          <div class="image-wrapper">
            <img class="icon dark" :src="require(`@/assets/contact-icons/${icon.name}.svg`)" :alt="icon.name" target="_blank">
          </div>
        </a>
      </div>
    </div>

    <div class="footer">
      gwynn causing
    </div>
  </ComponentWrapper>
</template>
<script setup>
import ComponentWrapper from './ComponentWrapper.vue';

const contactIcons = [
  {
    name: 'Email',
    url: 'mailto:gwynn.causing.business@gmail.com'
  },
  {
    name: 'LinkedIn',
    url: 'https://www.linkedin.com/in/gwynn-causing/'
  },
  {
    name: 'GitHub',
    url: 'https://github.com/gwynncausing'
  },

]
const socialIcons = [
  {
    name: 'Instagram',
    url: 'https://www.instagram.com/gwynncausing/'
  },
  {
    name: 'TikTok',
    url: 'https://www.tiktok.com/@gwynncausing'
  },
]
</script>
<style lang="scss" scoped>
@media (min-width: $sm) {
  .contact-wrapper {
    display: flex;
    gap: 24px;
    justify-content: center;
    margin-top: 5rem;
    .contacts,
    .socials {
      margin: 0;
    }
  }
}

.contacts {
  margin-top: 6rem;
}

.contacts,
.socials {
  display: flex;
  gap: 24px;
  justify-content: center;
  margin-bottom: 16px;
}
.image-wrapper {
  padding: 10px;
  background: rgba(#ffffff,  .1);
  border-radius: 50%;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: $transitionOption;
  
  &:hover {
  background: rgba(#ffffff,  .2);

    img.icon {
      filter: grayscale(1) invert(.8);
      scale: 1.15;
    }
  }

  img {
    height: 30px;
    transition: $transitionOption;
  }
}

.footer {
  margin: 9rem 0 1.5rem;
  text-align: center;
  color: #636363;
}
</style>