<template>
  <section :id="id">
    <div class="container">
      <h2 class="title focus" tabindex="0">{{title || id}} <span class="emoji">{{ emoji }}</span></h2>
      <div v-if="comingSoon" class="coming-soon fade-text-up">
        coming soon...
      </div>
      <div v-else class="content">
        <slot></slot>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: "ComponentWrapper",
  props: {
    id: {
      type: String,
      required: true
    },
    title: {
      type: String,
      default: ""
    },
    emoji: {
      type: String,
      default: ""
    },
    comingSoon: {
      type: Boolean,
      default: false
    }
  }

}
</script>
<style lang="scss" scoped>
.coming-soon {
  margin-top: 5rem;
}
section {
  background-color: $black;
  color: $white;
  text-align: left;
  scroll-margin-top: 50px;

  .title {
    color: rgba(#ffffff, .25);
    position: absolute;
    top: 32px;;
    left: 36px;
    transform-origin: left;
    transform: scale(4);
    font-size: 1rem;
    font-weight: normal;
    margin: 0;

    @media (max-width: $sm) {
      top: 38px;;
      transform: scale(3);
    }
  }

  .content {
    margin-top: 1rem;
  }
  
  .emoji {
    user-select: none;
    -webkit-user-drag: none;
    display: none;

    @media (min-width: $sm) {
      display: inline-block;
    }
  }
}
</style>