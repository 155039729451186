
export const BREAKPOINTS = {
  SMALL: 576,
  MEDIUM: 768,
  LARGE: 992,
}

const useBreakpoint = () => {
  // $sm: 576px;
  // $md: 768px;
  // $lg: 992px;
  // $xl: 1200px;
  const isSmall = () => {
    return window.innerWidth <= BREAKPOINTS.SMALL
  }
  const isMedium = () => {
    return window.innerWidth <= BREAKPOINTS.MEDIUM
  }
  const isLarge = () => {
    return window.innerWidth <= BREAKPOINTS.LARGE
  }

  return {
    isSmall,
    isMedium,
    isLarge
  };
}

export default useBreakpoint;