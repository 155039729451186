<template>
  <TheNavigation />
  <TheHero />
  <TheAbout />
  <TheExperience />
  <TheEducation />
  <!-- <TheDevelopment /> -->
  <TheProject />
  <TheContact />
  <ViewOnDesktopModal />
</template>

<script setup>
import TheNavigation from '@/components/TheNavigation.vue';
import TheHero from '@/components/TheHero.vue';
import TheAbout from '@/components/TheAbout.vue'
import TheExperience from '@/components/TheExperience.vue';
import TheEducation from '@/components/TheEducation.vue';
// import TheDevelopment from '@/components/TheDevelopment.vue';
import TheProject from '@/components/TheProject.vue';
import TheContact from '@/components/TheContact.vue';
import ViewOnDesktopModal from '@/components/ViewOnDesktopModal'

import { onMounted } from 'vue';
// import { useRoute } from 'vue-router'

// const route = useRoute()
// const hash = ref(route.hash)

onMounted(() => {
  // setTimeout(() => {
  //   if (hash.value) {
  //     const refName = hash.value.replace('#', '')
  //     scrollToAnchorPoint(refName)
  //   }
  // })

  document.addEventListener('keyup', function (e) {
    if (e.key === 'Tab') {
      const focusedElement = document.activeElement;
      if (focusedElement.classList.contains('focus')) {
        focusedElement.scrollIntoView({ behavior: 'smooth', block: 'center', });
      }
    }
  });
})

// function scrollToAnchorPoint(refName) {
//   const el = document.getElementById(refName)
//   if (el) {
//     el.scrollIntoView({ behavior: 'smooth', block: 'start' })
//   }
// }

</script>
