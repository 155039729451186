import { TECH, TOOLS } from "./technologies"

export const WORKS = [
  {
    company: "Newlogic",
    position: "Software Developer",
    start_date: "December 2022",
    end_date: "",
    is_still_working: true,
    is_freelance: false,
    freelance_title: "",
    works: [
      "innovating things ✨",
      // "Mentor junior software developers"
    ],
    tech_stack: [],
    tools: []
  },
  // {
  //   company: "Raykan Technologies",
  //   position: "Frontend Developer",
  //   start_date: "October 2023",
  //   end_date: "December 2023",
  //   is_still_working: false,
  //   is_freelance: true,
  //   freelance_title: "Part-time Freelance",
  //   works: [
  //     "Created new modules and revise existing modules",
  //     "Conduct code reviews and keep up with industry best practices",
  //     "Work closely with the company owner, other developers, and managers",
  //   ],
  //   tech_stack: [
  //     TECH.VUE, 
  //     TECH.GIT, 
  //     TECH.BITBUCKET, 
  //     TECH.SCSS
  //   ],
  //   tools: [
  //     TOOLS.AZURE,
  //   ]
  // },
  {
    company: "Advance Exteriors",
    position: "Frontend Developer & UI Designer",
    start_date: "November 2021",
    end_date: "December 2022",
    is_still_working: false,
    is_freelance: true,
    freelance_title: "Full-time Freelance",
    works: [
      "Spearheaded the frontend of the project to create web pages, wireframes, mockup, and prototype, and documentation",
      "Collaborate with project manager, backend developers to manage the system",
      "Work closely with the company owner",
      "Improve accessibility based on W3C standards - Web Content Accessibility Guidelines (WCAG)"
    ],
    tech_stack: [
      TECH.VUE, 
      TECH.GIT, 
      TECH.GITHUB, 
      TECH.SCSS,
      TECH.LARAVEL,
      TECH.JS,
      TECH.PHP
    ],
    tools: [
      TOOLS.JIRA,
      TOOLS.FIGMA,
      TOOLS.MIRO
    ]
  },
  {
    company: "Freelance",
    position: "Web Developer",
    start_date: "August 2021",
    end_date: "July 2022",
    is_still_working: false,
    is_freelance: true,
    freelance_title: "Project-based Freelance",
    works: [
      "Create web design sketches, wireframes, mockup, and prototype using Figma",
    ],
    tech_stack: [
      TECH.VUE, 
      TECH.GIT, 
      TECH.GITHUB, 
      TECH.SCSS,
    ],
    tools: [
      TOOLS.AZURE,
      TOOLS.FIGMA
    ]
  },
  {
    company: "Payruler",
    position: "Full Stack Developer",
    start_date: "February 2021",
    end_date: "September 2022",
    is_still_working: false,
    is_freelance: false,
    freelance_title: "",
    works: [
      "Spearheaded the frontend and backend of a project",
      "Build API's using NestJS with MongoDB, and made use of Azure's Video Indexer",
      "Implement designs from Figma to VueJS"
    ],
    tech_stack: [
      TECH.VUE, 
      TECH.GIT, 
      TECH.GITHUB, 
      TECH.SCSS,
    ],
    tools: [
      TOOLS.AZURE,
      TOOLS.FIGMA,
      TOOLS.NGINX
    ]
  },
]