import axios from 'axios';

export class MetaScaper {
  constructor(url) {
    if(url.includes('//'))
      this.url = url;
    else 
      this.url = 'https://' + url;
  }

  async scapeUrl() {
    const {data: response} = await axios.get(this.url);

    const parser = new DOMParser();
    this.doc = parser.parseFromString(response, 'text/html');
  }

  getOGMeta() {
    const metaTags = Array.from(this.doc.querySelectorAll('meta[property^="og:"]'));
    const meta = {}
    metaTags.forEach(tag => {
      meta[tag.getAttribute('property').replace(/og:/g, "")] = tag.getAttribute('content')
    });

    const faviconLink = this.doc.querySelector('link[rel="icon"]') || this.doc.querySelector('link[rel="shortcut icon"]');
    let faviconUrl = null;
    if (faviconLink) {
      const faviconHref = faviconLink.getAttribute('href');
      faviconUrl = new URL(faviconHref, this.url).href;
    }
    meta.favicon = faviconUrl

    return meta;
  }

  getGCMeta() {
    const metaTags = Array.from(this.doc.querySelectorAll('meta[name^="gwynncausing:"]'));
    const meta = {}
    metaTags.forEach(tag => {
      meta[tag.getAttribute('name').replace(/gwynncausing:/g, "gc")] = tag.getAttribute('content')
    });
    return meta;
  }
}

export const PROJECT_LINKS = [
  'https://gwynncausing.com/',
  'https://mapbox.gwynncausing.com/',
  'https://hr.gwynncausing.com/'
]

export const PROJECT_DATA = [
  {
    title: 'Bud: Your Project Buddy',
    description: 'A system for managing research that includes a real-time drag-and-drop editor for creating research papers. It comes with an autoformatter, comment section, submission and feedback system. We utilized this platform to simulate industry work, incorporating elements such as Figma design, UML diagrams, basic CI/CD, Docker, and deployment on Azure (took down when the credits ran out).',
    image: 'https://res.cloudinary.com/gwynncausing/image/upload/v1707095484/bud-image.webp',
    favicon: 'https://res.cloudinary.com/gwynncausing/image/upload/v1707095483/bud-logo.webp',
    url: 'bud-your-project-buddy',
    gctags: 'vue, nestjs, figma, webrtc, mysql, docker, firebase, vuetify, tiptap, azure, scss'
  }
]