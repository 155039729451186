<template>
  <nav ref="nav">
    <a href="javascript:void(0)" class="burger" @click="toggleNavigationMenu">
      <div class="burger__top"></div>
      <div class="burger__middle"></div>
      <div class="burger__bottom"></div>
    </a>
    <div class="nav-links">
      <ul>
        <li><a href="#home" @click="hideNavigationMenu">Home</a></li>
        <li><a href="#about" @click="hideNavigationMenu">About</a></li>
        <li><a href="#experience" @click="hideNavigationMenu">Experience</a></li>
        <li><a href="#education" @click="hideNavigationMenu">Education</a></li>
        <li><a href="#projects" @click="hideNavigationMenu">Projects</a></li>
        <li><a href="#contact" @click="hideNavigationMenu">Contact</a></li>
      </ul>
    </div>
    <!-- <label class="switch">
      <img class="image" src="@/assets/image.svg" />
      <img class="text" src="@/assets/text.svg" />
      <input type="checkbox">
      <span class="slider round"></span>
    </label> -->
  </nav>
</template>
<script setup>
import { ref, onBeforeUnmount } from 'vue'
import useBreakpoint from "@/composables/useBreakpoint"

const nav = ref(null)
const mobileNavClass = 'nav-mobile-visible'

const toggleNavigationMenu = () => {
  nav.value.classList.toggle(mobileNavClass)
}

const hideNavigationMenu = (force = false) => {
  if(useBreakpoint().isSmall() || force)
    nav.value.classList.remove(mobileNavClass)
}

const resizeWindow = () => {
  const hasClass = nav.value.classList.contains(mobileNavClass)
  const isSmall = useBreakpoint().isSmall()
  if(hasClass && isSmall) {
    hideNavigationMenu(true);
  }
}
window.addEventListener('resize', resizeWindow);

onBeforeUnmount(() => {
  window.removeEventListener('resize', resizeWindow);
})

</script>
<style lang="scss" scoped>
$transition: all ease-in-out 200ms;
nav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 80px;
  background: linear-gradient($black, transparent);
  user-select: none;
  z-index: 999;

  .nav-links {
    transition: $transition;
  }
  ul {
    padding: 0;
    margin: 16px 16px 8px;
    display: flex;
    flex-direction: row;
    gap: 16px;
    justify-content: flex-end;
  }

  .burger {
    display: none;
  }

  @media (max-width: $sm) {
    .nav-links {
      display: none;
    }
    .burger {
      display: block;
    }
  }
}

.burger {
  position: fixed;
  top: 0;
  right: 0;
  margin: 24px;
  z-index: 2;

  background-color: rgba($white, .2 );
  height: 36px;
  width: 36px;
  padding: 5px;
  border-radius: 4px;
  display: block;
  [class^="burger__"] {
    height: 4px;
    width: 35px;
    background-color: $white;
    transition: $transition;
    border-radius: 2px;
    margin-top: 6px;
    position: relative;
  }
  &__middle::after {
    content: "";
    position: absolute;
    height: 4px;
    width: 35px;
    background-color: $white;
    transition: $transition;
    border-radius: 2px;
    left: 0;
  }
}

nav.nav-mobile-visible {
  .burger {
    &__middle {
      transform-origin: center;
      transform: rotateZ(45deg);
      &::after {
        transform-origin: center;
      transform: rotateZ(-90deg);
      }
    }
    &__top,
    &__bottom {
      opacity: 0;
    }
  }

  .nav-links {
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: $black;

    ul {
      margin: 5rem 3rem;
      flex-direction: column;
      text-align: left;
      font-size: 3rem;

      li {
        animation: fadeRight both linear;
        animation-duration: 450ms;

        @for $i from 1 through 10 { 
          &:nth-child(#{$i}) {
            animation-delay: ($i * 50ms) ;
          }
        }
      }
    }
  }
}



/* The switch - the box around the slider */
.switch {
  position: relative;
  display: block;
  width: 60px;
  height: 24px;
  float: right;
  margin-right: 16px;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }


  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(90deg, $black 50%, #ccc 50%);
    overflow: hidden;
    transition: all ease 400ms;
    border-radius: 16px;
  }


  input {
    &:checked+.slider {
      background-position-x: 30px;
    }
  }

  .image,
  .text {
    height: 16px;
    width: 16px;
    z-index: 1;
    position: absolute;
    bottom: 4px;
    filter: invert(1);
  }

  .image {
    left: 8px;
  }

  .text {
    right: 8px;
  }
}

@keyframes fadeRight {
  from {
    opacity: 0;
    translate: -100%;
  }
  to {
    opacity: 1;
    translate: 0%;
  }
}
</style>