<template>
  <div class="not-found">
    <div>
      <h1>The page you are looking for cannot be found.</h1>
      <router-link to="/">click here to Go home</router-link>
    </div>
  </div>
</template>
<script>
export default {
  
}
</script>
<style lang="scss" scoped>
.not-found {
  display: flex;
  flex-direction: column;
  height: 80vh;
  justify-content: center;
}
</style>