<template>
  <div popover ref="popover" class="modal" @beforetoggle="beforetoggleModal">
    <div class="header">
      <button ref="firstTab" class="button" @click="closeModal">close</button>
    </div>
    <div v-if="selectedProject !== null" class="content">
      <img class="cover" :src="selectedProject?.image" alt="">
      <div class="title">{{ selectedProject.title }}</div>

      <div class="links">
        <a v-if="selectedProject.gcgithub" :href="selectedProject.gcgithub" target="_blank" class="button">
          view github <OpenNewTab />
        </a>
        <a v-if="isLink" @keydown="goToNextTab" :href="selectedProject.url" target="_blank" class="button">
          view project <OpenNewTab />
        </a>
      </div>

      <div class="description">
        <p class="descrption-1">{{ selectedProject.description }}</p>
        <p class="descrption-2">{{ selectedProject.description !== selectedProject.gcdescription ? selectedProject.gcdescription : null}}</p>
      </div>

      <div class="tags">
        <span v-for="(tag, tagIndex) in selectedProject.gctags?.split(', ')" :key="tagIndex" class="tag">
          {{ tag }}
        </span>
      </div>
    </div>
  </div>
</template>
<script setup>
import { defineProps, defineEmits, ref, watch } from 'vue';
import OpenNewTab from '@/components/icons/OpenNewTab'


const props = defineProps(['selectedProject'])
const isLink = ref(false);
watch(() => props.selectedProject, (newProject) => {
  if(newProject) {
    isLink.value = containsTopLevelDomain(newProject.url)
  }
  
});

const emit = defineEmits(['hide'])
const beforetoggleModal = (event) => {
  if(event.newState === 'open') {
    document.getElementsByTagName("body")[0].style.overflowY = 'hidden';
  }
  else {
    emit('hide')
    document.getElementsByTagName("body")[0].style.overflowY = 'auto';
  }
}

const firstTab = ref(null);
const goToNextTab = (event) => {
  if(event.key) {
    event.preventDefault();
    firstTab.value.focus();
  }
}

const popover = ref(null);
const closeModal = () => {
  popover.value.hidePopover()
}

const containsTopLevelDomain = (str) => {
  // Regular expression to match strings ending with any top-level domain
  var regex = /\.[a-z]{2,}$/i;
  return regex.test(str);
}

</script>
<style lang="scss" scoped>
.modal {
  width: -webkit-fill-available;
  height: fit-content;
  min-width: 300px;
  min-height: 500px;
  max-width: 800px;
  max-height: 80vh;

  .header {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 24px;
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .cover {
      max-height: 300px;
      height: 100%;
      width: auto;
      object-fit: contain
    }

    .title {
      text-align: center;
    }

    .tags {
      margin-top: 16px;
      display: flex;
      flex-wrap: wrap;
      gap: 8px;

      .tag {
        padding: 4px 8px;
        border-radius: 4px;
        background: rgba(#fff, .3);
        font-size: .8rem;
      }
    }

    .links {
      display: flex;
      gap: 8px;
      justify-content: center;
    }
  }
}
</style>