<template>
  <section id="home">
    <div class="background"></div>
    <div class="content">
      <h1 class="name">Gwynn Causing</h1>
      <div class="sub">
        <span class="title">Web Developer</span>
        <span class="email focus"><a
            href="mailto:gwynn.causing.business@gmail.com">gwynn.causing.business@gmail.com</a></span>
      </div>
    </div>
    <div class="links">
      <ul>
        <li><a href="https://github.com/gwynncausing" target="_blank">github</a></li>
        <li><a href="https://www.linkedin.com/in/gwynn-causing/" target="_blank">linkedin</a></li>
      </ul>
    </div>
  </section>
</template>
<script setup>
</script>
<style lang="scss" scoped>
#home {
  background-image: url('@/assets/hero.webp');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  height: 60vh;
  position: relative;
  color: $white;
  padding: 16px;
  transition: height $transitionOption;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 32px;

  &>.background {
    position: absolute;
    content: "";
    inset: 0 0 0 0;
    background-color: rgba($color: #000000, $alpha: .4);
  }

  &>.content {
    position: relative;
    margin-bottom: 10vh;
    transition: margin-bottom $transitionOption;

    .name {
      font-size: 3rem;
      margin-bottom: 12px;
      transition: font-size $transitionOption;
      font-weight: normal;
    }

    .sub .title::after {
      content: " | ";
    }
  }

  &>.links {
    position: absolute;
    bottom: 0;
    right: 0;
    margin: 16px;

    ul {
      margin: 0;
      display: flex;
      gap: 16px;
    }
  }

  @media (min-width: $md) {
    height: 80vh;

    &>.content {
      margin-bottom: 20vh;

      .name {
        font-size: 6rem;
      }
    }
  }
}
</style>