<template>
  <router-view/>
</template>

<style lang="scss">
html {
  scroll-behavior: smooth;
  background-color: $black;
}
#app {
  font-family: Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: $white;
  text-transform: lowercase;
  letter-spacing: 1.1px;
}
body {
  margin: 0;
  overflow-x: hidden;
}

a {
  color: unset;
  text-decoration: unset;
}

p {
  margin-top: 0;
  margin-bottom: 1.5em;
  line-height: 1.5rem;
}

ul {
  list-style: none;
}

h1 {

}

img.icon {
  filter: grayscale(1);

  &.dark {
    filter: grayscale(1) invert(.6);
  }
}

@media (prefers-reduced-motion) {
  * {
    animation: none !important;
  }
}

ul.listing {
  padding: 0;
  line-height: 1.5rem;

  li {
    ul {
      margin-top: 4px;
      padding-left: 1rem;
      li {
        margin-bottom: 4px;
      }
    }
  }
}

.fade-text-right {
  color: transparent;
  background: linear-gradient(90deg, #fff 70%, transparent);
  width: fit-content;
  background-size: 200%;
  background-position-x: -100%;
  background-repeat: no-repeat;
  background-clip: text;
  -webkit-background-clip: text;
  animation: scrollingText linear both;
  animation-timeline: view();
  animation-range: contain 5% contain 40%;
}

@keyframes scrollingText {
  from {
    background-position-x: 200%;
  }
  to {
    background-position-x: 0%;
  }
}

.fade-text-up {
  animation: fade both linear;
  animation-timeline: view();
  animation-range: contain 10% contain 30%;
}

@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

[popover], .modal {
  inset: 24px;
  overflow-y: scroll;
  border: none;
  background: $black;
  color: $white;
  animation: fade .2s ease-in-out;
  padding: 24px;
  border-radius: 8px;
  line-height: 25px;

  &::backdrop {
    backdrop-filter: blur(3px);
    background: rgba(#fff, .5);
  }
}

.button {
  width: fit-content;
  padding: 8px 16px;
  background: rgba(#fff, .2);
  border-radius: 2px;
  color: #fff;
  border: none;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}
</style>
